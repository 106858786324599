<template>
    <div class="container">
        <template v-if="!saveSuccess">
            <div class="title-container">
                <button class="back-button" @click="goBack"><i class="fas fa-arrow-left"></i></button>
                <h1>{{ $t('settingsTitle') }}</h1>
            </div>
            <p>{{ $t('customizeSettings') }}</p>
            <div class="settings-wrapper">
                <div class="setting" v-for="(setting, index) in settings" :key="index">
                    <div v-if="setting.type === 'toggle'">
                        <span>{{ translateOrFallback(setting.label) }}</span>
                        <div class="toggle-wrapper">
                            <VueToggle :name="setting.name" :activeColor="'#4CAF50'" :toggled="setting.value === 'on'"
                                @toggle="handleToggle(setting)" />
                        </div>
                        <span class="question-mark" @click="showDetails(setting.details)">{{
                            translateOrFallback('questionMarkHint') }}</span>
                    </div>

                    <div v-else-if="setting.type === 'dropdown'">
                        <label :for="setting.name">{{ translateOrFallback(setting.label) }}</label>
                        <select :id="setting.name" v-model="setting.value">
                            <option v-for="option in setting.options" :value="option.value" :key="option.value">
                                {{ translateOrFallback(option.label) }}
                            </option>
                        </select>
                        <span class="question-mark" @click="showDetails(setting.details)">{{
                            translateOrFallback('questionMarkHint') }}</span>
                    </div>
                    <div v-else-if="setting.type === 'detail'">
                        <span>{{ translateOrFallback(setting.label) }}</span>
                        <button class="detail-button" @click="navigateTo(setting.destination)">{{
                            translateOrFallback('clickButton') }}</button>
                    </div>
                </div>
                <div class="setting">
                    <label for="webLanguage" class="setting-label">{{ $t('webLanguageLabel') }}</label>
                    <select id="webLanguage" v-model="webLanguage" class="setting-dropdown">
                        <option v-for="(locale, index) in availableLocales" :value="locale" :key="index">
                            {{ $t(locale) }}
                        </option>
                    </select>
                </div>

            </div>
            <button class="button" :disabled="isSaving" @click="saveSettings">
                {{ isSaving ? $t('saving') : $t('save') }}
            </button>
        </template>
        <template v-else>
            <h1>{{ $t('settingsSavedTitle') }}</h1>
            <p>
                {{
                    $store.state.authUser.clockId && $store.state.authUser.clockId.startsWith('V3')
                        ? $t('settingsSavedDescriptionV3')
                        : $t('settingsSavedDescription')
                }}
            </p>
            <button class="button" @click="goHome">{{ $t('homeButton') }}</button>
        </template>
    </div>
</template>



<script>
import VueToggle from "vue-toggle-component";

export default {
    components: {
        VueToggle
    },
    data() {
        return {
            isSaving: false,
            saveSuccess: false,
            settings: [], // Initialize settings as an empty array
            webLanguage: this.$i18n.locale,
        };
    },
    created() {
        this.initializeSettings();
    },
    computed: {
        availableLocales() {
            return this.$i18n.availableLocales;
        },
    },
    methods: {
        translateOrFallback(key) {
            // console.log(key)
            const translation = this.$t(key);
            return translation !== key ? translation : key; // Return the key itself if no translation is found
        },
        initializeSettings() {
            const settingsDefinitions = [
                { name: "Language", current: "en", options: ["en", "de", "it", "fr"], type: 'dropdown', details: 'details.language' },
                { name: "Sound", current: "on", options: ["on", "off"], type: 'dropdown', details: 'details.sound' },
                { name: "Time Mode", current: "24h", options: ["24h", "12h"], type: 'dropdown', details: 'details.timeMode' },
                { name: "Offline Mode", current: "off", options: ["off", "on"], type: 'dropdown', details: 'details.offlineMode' },
                { name: "Menu Long Press", current: "Sync Clock", options: ["Sync Clock", "Apps", "Alarm", "Timer"], type: 'dropdown', details: 'details.menuHold' },
                { name: "Battery Saver", current: "off", options: ["off", "on"], type: 'dropdown', details: 'details.batterySaver' },
                { type: 'detail', name: 'Location', label: 'Location', details: 'details.location', destination: '/location' },
                { type: 'detail', name: 'Time & Date', label: 'Time & Date', details: 'details.timeDate', destination: '/time' },
            ];



            const userSettings = this.$store.state.authUser.settings;
            this.settings = settingsDefinitions.map((setting, index) => {
                const valueIndex = userSettings[index] || 0;
                let currentValue = setting.options?.[valueIndex] || setting.current;

                // Adjusting for toggle type settings to ensure boolean value
                if (setting.type === 'toggle') {
                    if (currentValue === "on") {
                        currentValue = 1;
                    } else {
                        currentValue = 0;
                    }
                }

                return {
                    ...setting,
                    current: currentValue,
                    label: setting.name, // Use the name as label
                    value: currentValue, // Use the mapped current value
                    // Provide options for dropdowns, ensure boolean values for toggles
                    options: setting.options ? setting.options.map(option => ({ label: option, value: option })) : undefined,
                };
            });
        },
        goBack() {
            this.$router.go(-1);
        },
        showDetails(detailKey) {
            const translatedDetail = this.$t(detailKey); // Translate the detail key to its corresponding text
            alert(translatedDetail); // Show the translated detail in an alert
        },
        handleToggle(setting) {
            setting.value = !setting.value;
        },
        async saveSettings() {
            this.isSaving = true;
            const settingsIndexes = this.settings
                .filter(setting => setting.type === 'dropdown') // Keep only dropdown settings
                .map(setting => setting.options.findIndex(option => option.value === setting.value)); // Get indexes of selected options


            try {
                const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/saveSettings`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        clockName: this.$store.state.authUser.name, // Assuming the clock name is stored here
                        settings: settingsIndexes
                    }),
                });

                if (!response.ok) {
                    throw new Error('Failed to save settings. Please try again.');
                }

                // Optionally, handle the response data
                await response.json();
                // console.log('Settings saved successfully');

                // Update settings in Vuex store
                this.$store.commit('updateSettings', settingsIndexes);

                this.$i18n.locale = this.webLanguage;
                localStorage.setItem('language', this.webLanguage);

                this.saveSuccess = true; // Update state to indicate success
            } catch (error) {
                console.error('Error saving settings:', error);
                alert(error.message); // Show error message
            } finally {
                this.isSaving = false;
            }
        },

        goHome() {
            this.$router.push({ name: 'CosmoHome' });
        },
        navigateTo(destination) {
            this.$router.push(destination);
        },
    },
};
</script>

<style scoped>
.title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.settings-wrapper {
    margin: 30px 0;
    /* Adjust the top and bottom margin as needed */
    width: 100%;
    /* Ensure it spans the width of its container */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Center-align the settings */
}

.setting {
    display: flex;
    align-items: center;
    justify-content: center;
    /* Adjust as necessary */
    margin-bottom: 15px;
}

.toggle-wrapper {
    transform: scale(0.8);
    display: inline-block;
}

/* This targets the direct container of the toggle and label, adjust as needed */
.setting>div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    /* Creates space between the label and the toggle */
}

.question-mark {
    cursor: pointer;
    color: rgb(100, 100, 100);
    /* Ensure the question mark is white */
    margin-left: 5px;
    font-size: 16px;
    /* Adjust the size of the question mark */
    border: 1px solid rgb(104, 104, 104);
    /* Create a border around the question mark */
    border-radius: 50%;
    /* Make the border rounded */
    padding: 2px;
    /* Add some padding around the question mark */
    display: inline-flex;
    /* Use flex to center the content */
    align-items: center;
    /* Align items vertically */
    justify-content: center;
    /* Center content horizontally */
    text-align: center;
    /* Ensure text is centered */
    padding: 0;
    min-width: 30px;
    max-width: 30px;
    min-height: 30px;
    max-height: 30px;
}

.vue-toggle {
    transform: scale(0.1);
    /* Adjust scale as needed to make the toggle smaller */
}

.setting select {
    padding: 5px 10px;
    background-color: transparent;
    /* No background */
    color: white;
    /* Text color */
    border: 1px solid white;
    /* White border */
    border-radius: 5px;
    /* Rounded corners */
    cursor: pointer;
    /* Pointer cursor on hover */
    font-size: 16px;
    /* Text size */
    -webkit-appearance: none;
    /* For some browsers to remove default styling */
    appearance: none;
}

.setting option {
    background-color: #262626;
    /* No background */
    color: white;
    /* Text color */
}

.button {
    margin-top: 10px;
}

.button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.detail-button {
    padding: 5px 10px;
    background-color: transparent;
    color: white;
    border: 1px solid white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-left: 10px;
    /* Creates space between the label and the button */
    transition: background-color 0.3s ease;
}

.detail-button:hover {
    background-color: rgba(255, 255, 255, 0.2);
}


.container p {
    margin-bottom: 0px;
}

.setting-label {
    margin-right: 10px;
    /* Add a right margin to create space between the label and the dropdown */
}
</style>
