<template>
    <div class="container">
        <template v-if="!saveSuccess">
            <div class="title-container">
                <button class="back-button" @click="goBack"><i class="fas fa-arrow-left"></i></button>
                <h1>{{ $t('timer1SettingsTitle') }}</h1>
            </div>
            <p>{{ $t('customizeTimer1Settings') }}</p>

            <div class="settings-wrapper" v-if="timerSettings && timerSettings.length">
                <div class="timer-card">
                    <h3>{{ $t('timer') }}</h3>
                    <div class="setting" v-for="(setting, settingIndex) in timerSettings" :key="settingIndex">
                        <div v-if="setting.type === 'dropdown'">
                            <label :for="setting.name">{{ translateAndCapitalize(setting.label) }}</label>
                            <select :id="setting.name" v-model="setting.current">
                                <option v-for="option in setting.options" :value="option.value" :key="option.value">
                                    {{ translateAndCapitalize(option.label.toLowerCase()) }}
                                </option>
                            </select>
                        </div>
                        <div v-else-if="setting.type === 'number'">
                            <label :for="setting.name">{{ translateAndCapitalize(setting.label) }}</label>
                            <input type="number" :id="setting.name" v-model.number="setting.current" :min="setting.min"
                                :max="setting.max">
                        </div>
                    </div>
                </div>
            </div>
            <button class="button" :disabled="isSaving" @click="saveAlarms">
                {{ isSaving ? $t('savingButton') : $t('saveButton') }}
            </button>
        </template>
        <template v-else>
            <h1>{{ $t('settingsSavedTitle') }}</h1>
            <p>
                {{
                    $store.state.authUser.clockId && $store.state.authUser.clockId.startsWith('V3')
                        ? $t('settingsSavedDescriptionV3')
                        : $t('settingsSavedDescription')
                }}
            </p>
            <button class="button" @click="goHome">{{ $t('homeButton') }}</button>
        </template>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isSaving: false,
            saveSuccess: false,
            timerSettings: [],
            selectedTab: 0, // Added selectedTab
        };
    },
    created() {
        this.initializeTimers();
        console.log(this.timerSettings)
    },
    methods: {
        initializeTimers() {
            this.timerSettings = [
                {
                    type: 'number',
                    name: 'minutes',
                    label: 'Minutes',
                    current: this.$store.state.authUser.timerMinutes,
                    min: 0,
                    max: 90
                },
                {
                    type: 'number',
                    name: 'seconds',
                    label: 'Seconds',
                    current: this.$store.state.authUser.timerSeconds,
                    min: 0,
                    max: 59
                },
                {
                    type: 'dropdown',
                    name: 'soundName',
                    label: 'Sound',
                    current: this.$store.state.authUser.timerSoundName.toLowerCase(),
                    options: [
                        { label: 'churchbell', value: 'churchbell' },
                        { label: 'bowl', value: 'bowl' },
                        { label: 'cuckoo', value: 'cuckoo' },
                        { label: 'rooster', value: 'rooster' },
                        { label: 'futuristic', value: 'futuristic' }
                    ]
                },
                {
                    type: 'dropdown',
                    name: 'volume',
                    label: 'Sound Volume',
                    current: this.$store.state.authUser.timerVolume.toString(),
                    options: Array.from({ length: 10 }, (_, i) => ({
                        label: `${(i + 1) * 10}%`,
                        value: `${(i + 1) * 10}`,
                    }))
                }
            ]
        },
        getDayLabel(daysArray) {
            const dayOptions = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
            let selectedDaysCount = daysArray.filter(day => day === 1).length;

            if (selectedDaysCount === 5 && daysArray[0] && daysArray[1] && daysArray[2] && daysArray[3] && daysArray[4] && !daysArray[5] && !daysArray[6]) {
                return "Weekdays";
            } else if (selectedDaysCount === 2 && daysArray[5] && daysArray[6]) {
                return "Weekend";
            } else if (selectedDaysCount === 7) {
                return "Every Day";
            } else if (selectedDaysCount === 1) {
                return dayOptions[daysArray.indexOf(1)];
            } else {
                return daysArray.map((day, index) => (day === 1 ? dayOptions[index] : '')).filter(Boolean).join(', ');
            }
        },
        async connect() {
            // The connect method you provided
            const clockName = this.$store.state.authUser.name;
            this.isLoading = true;
            // console.log("Connecting to Cosmo Clock");
            try {
                const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/login?identifier=${clockName}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                if (!response.ok) {
                    throw new Error('Failed to connect. Please try again.');
                }
                const data = await response.json();
                // console.log("Connected successfully");
                this.connectedCosmo = data; // Store the received Cosmo document
                this.$store.dispatch('authenticateUser', data);
            } catch (error) {
                console.error(error);
            } finally {
                this.isLoading = false; // Reset loading state
            }
        },
        async saveAlarms() {
            this.isSaving = true;
            try {
                // Prepare the request body with the specific timer settings
                const requestBody = {
                    clockName: this.$store.state.authUser.name,
                    timerSeconds: this.timerSettings.find(setting => setting.name === 'seconds').current,
                    timerMinutes: this.timerSettings.find(setting => setting.name === 'minutes').current,
                    timerSoundName: this.timerSettings.find(setting => setting.name === 'soundName').current,
                    timerVolume: parseInt(this.timerSettings.find(setting => setting.name === 'volume').current),
                };

                // Send the updated timer settings to the backend
                const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/saveTimer`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestBody),
                });

                if (!response.ok) {
                    throw new Error('Failed to save timer settings. Please try again.');
                }

                // Handle the response data
                await response.json();

                // Update the store with the new timer settings if needed
                await this.connect();
                this.saveSuccess = true;
            } catch (error) {
                console.error('Error saving timer settings:', error);
                alert(error.message); // Show error message
            } finally {
                this.isSaving = false;
            }
        },
        getDaysArray(dayLabel) {
            const daysArray = Array(7).fill(false);
            if (dayLabel === 'Weekdays') {
                daysArray.fill(true, 0, 5);
            } else if (dayLabel === 'Weekend') {
                daysArray[5] = true;
                daysArray[6] = true;
            } else if (dayLabel === 'Every Day') {
                daysArray.fill(true, 0, 7);
            } else {
                const dayOptions = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
                const index = dayOptions.indexOf(dayLabel);
                if (index !== -1) {
                    daysArray[index] = true;
                }
            }
            return daysArray;
        },
        translateAndCapitalize(key) {
            let translation = this.$t(key);
            if (translation === key) {
                translation = key;
            }
            return translation.toLowerCase().replace(/(?:^|\s|-)\S/g, a => a.toUpperCase());
        },
        goBack() {
            this.$router.go(-1);
        },
        goHome() {
            this.$router.push({ name: 'CosmoHome' });
        },
    },
};
</script>

<style scoped>
.delete-confirmation {
    background-color: #333;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    margin-top: 20px;
}

.confirm-button {
    margin-right: 10px;
}

.title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.settings-wrapper {
    margin: 30px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tabs {
    display: flex;
    justify-content: center;
}

.tabs button {
    background-color: #333;
    border: none;
    color: white;
    padding: 10px 20px;
    cursor: pointer;
    margin: 0 5px;
    border-radius: 20px;
    font-size: 16px;
}

.tabs button.active {
    background-color: #555;
}

.timer-card {
    background: #333;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
    transition: transform 0.3s ease;
}

.setting {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    width: 80%;
    gap: 10px;
}

.setting input[type="number"] {
    padding: 5px 10px;
    background-color: transparent;
    color: white;
    border: 1px solid white;
    border-radius: 5px;
    width: 80px;
    text-align: center;
    font-size: 16px;
}

.setting>div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    /* Creates space between the label and the toggle */
}

.setting select {
    padding: 5px 10px;
    background-color: transparent;
    color: white;
    border: 1px solid white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    -webkit-appearance: none;
    appearance: none;
}

.setting option {
    background-color: #262626;
    color: white;
}

.button {
    margin-top: 10px;
}

.button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
</style>
