<template>
    <div class="container">
        <div class="title-container">
            <button class="back-button" @click="goBack"><i class="fas fa-arrow-left"></i></button>
            <h1>{{ $t('userManualTitle') }}</h1>
        </div>
        <div class="language-buttons">
            <button class="button" @click="changeLanguage('en')">EN</button>
            <button class="button" @click="changeLanguage('de')">DE</button>
            <button class="button" @click="changeLanguage('it')">IT</button>
            <button class="button" @click="changeLanguage('fr')">FR</button>
        </div>
        <div class="setup-content">
            <!-- Quick Start Guide -->
            <section>
                <h2>{{ $t('quickStartGuide') }}</h2>
                <p>{{ $t('quickStartWelcome') }}</p>

                <div class="card">
                    <h3>{{ $t('quickStartStep1Title') }}</h3>
                    <p>{{ $t('quickStartStep1') }}</p>
                </div>
                <div class="card">
                    <h3>{{ $t('quickStartStep2Title') }}</h3>
                    <p>{{ $t('quickStartStep2') }}</p>
                </div>
                <div class="card">
                    <h3>{{ $t('quickStartStep3Title') }}</h3>
                    <p>{{ $t('quickStartStep3') }}</p>
                </div>
                <div class="card">
                    <h3>{{ $t('quickStartStep4Title') }}</h3>
                    <p>{{ $t('quickStartStep4') }}</p>
                </div>
                <div class="card">
                    <h3>{{ $t('quickStartStep5Title') }}</h3>
                    <p
                        v-html="$t('quickStartStep5', { webAppLink: `<a href='${$t('webAppURL')}' target='_blank' style='color: white;'>${$t('webAppLinkText')}</a>` })">
                    </p>
                </div>
                <div class="card">
                    <h3>{{ $t('quickStartStep6Title') }}</h3>
                    <p>{{ $t('quickStartStep6') }}</p>
                </div>
                <div class="card">
                    <h3>{{ $t('quickStartStep7Title') }}</h3>
                    <p>{{ $t('quickStartStep7') }}</p>
                </div>

                <div class="card">
                    <h3>{{ $t('quickStartCompleteTitle') }}</h3>
                    <p>{{ $t('quickStartComplete') }}</p>
                </div>
            </section>

            <!-- Navigation -->
            <section>
                <h2>{{ $t('navigation') }}</h2>
                <p>{{ $t('navigationDescription') }}</p>

                <div class="card">
                    <h3>{{ $t('navigationMiddleButtonTitle') }}</h3>
                    <p>{{ $t('navigationMiddleButton') }}</p>
                </div>
                <div class="card">
                    <h3>{{ $t('navigationLeftRightButtonsTitle') }}</h3>
                    <p>{{ $t('navigationLeftRightButtons') }}</p>
                </div>
            </section>

            <!-- On/Off if Problems -->
            <section>
                <h2>{{ $t('onOffIfProblems') }}</h2>
                <div class="card">
                    <p>{{ $t('onOffIfProblemsDescription') }}</p>
                </div>
            </section>

            <!-- Connect WiFi -->
            <section>
                <h2>{{ $t('connectWiFi') }}</h2>
                <div class="card">
                    <p>{{ $t('connectWiFiDescription') }}</p>
                </div>
            </section>

            <!-- Create Apps -->
            <section>
                <h2>{{ $t('createApps') }}</h2>
                <div class="card">
                    <p>{{ $t('createAppsDescription') }}</p>
                </div>
            </section>

            <!-- Battery Empty Icon Reload -->
            <section>
                <h2>{{ $t('batteryEmptyIconReload') }}</h2>
                <div class="card">
                    <p>{{ $t('batteryEmptyIconReloadDescription') }}</p>
                </div>
            </section>

            <!-- Alarm Icon Stop and Start -->
            <section>
                <h2>{{ $t('alarmIconStopStart') }}</h2>
                <div class="card">
                    <p>{{ $t('alarmIconStopStartDescription') }}</p>
                </div>
            </section>

            <!-- Forgot Your Clock's Name or ID -->
            <section>
                <h2>{{ $t('forgotNameOrID') }}</h2>
                <div class="card">
                    <p>{{ $t('forgotInstructions') }}</p>
                </div>
            </section>

            <!-- Need Help -->
            <section>
                <h2>{{ $t('needHelp') }}</h2>
                <div class="card">
                    <p
                        v-html="$t('helpMessage', { contactEmail: `<a href='mailto:${$t('contactEmail')}' style='color: white;'>${$t('contactEmail')}</a>` })">
                    </p>
                </div>
                <!-- Contact Button -->
                <a href="https://thecosmoclock.com/contact" class="button" target="_blank">{{ $t('contactButtonText') }}</a>
            </section>

        </div>
    </div>
</template>

<script>
export default {
    methods: {
        goBack() {
            this.$router.go(-1); // Go back to the previous page
        },
        changeLanguage(lang) {
            this.$i18n.locale = lang; // Change the language
        }
    },
};
</script>

<style scoped>
/* Global box-sizing */
*,
*::before,
*::after {
    box-sizing: border-box;
}

.setup-content a {
    color: white;
    /* Set link color to white */
    text-decoration: none;
    /* Optional: removes underline from links */
    font-weight: bold;
    word-break: break-word;
    /* Ensure long URLs wrap correctly */
}

/* Add hover effect for better user experience */
.setup-content a:hover {
    color: #ccc;
    /* Change link color on hover */
}

.title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.language-buttons {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.language-buttons .button {
    background-color: #333;
    border: none;
    color: white;
    padding: 10px 20px;
    cursor: pointer;
    margin: 0 5px;
    border-radius: 20px;
    transition: background-color 0.3s;
}

.language-button.active {
    background-color: #555;
}

.language-buttons .button:hover {
    background-color: #ddd;
    /* Change button background on hover */
}

.setup-content h3 {
    margin-top: 20px;
    margin-bottom: 10px;
}

.setup-content p {
    margin-bottom: 15px;
    padding: 0 20px;
    word-break: break-word;
    /* Ensure text wraps within paragraphs */
}

.container h1 {
    margin: 0;
    padding: 0;
}

.container p {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0 20px;
}

.button {
    margin-top: 20px;
    width: auto;
}

.card {
    background: #333;
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 20px;
    color: white;
    width: 100%;
    word-wrap: break-word;
    /* Ensure content wraps within the card */
}

.card a {
    color: white;
    /* Set link color to white */
    text-decoration: none;
    /* Removes underline from links */
    font-weight: bold;
    word-break: break-word;
    /* Ensure long URLs wrap correctly */
}

.card a:hover {
    color: #ccc;
    /* Change link color on hover */
}

.container {
    min-height: 100vh;
    padding: 20px;
    color: white;
    overflow-x: hidden;
    /* Prevent horizontal scrolling */
}

.title-container h1 {
    margin: 0;
    padding: 0;
    color: white;
    font-size: 2em;
}

/* Centering content */
.setup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.setup-content section {
    width: 100%;
    max-width: 800px;
    padding: 0 10px;
    /* Add horizontal padding to prevent overflow */
}

.card {
    width: 100%;
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .container {
        padding: 10px;
    }

    .setup-content p {
        padding: 0 10px;
    }

    .setup-content a {
        word-wrap: break-word;
        /* Ensure long URLs wrap correctly */
    }

    .title-container h1 {
        font-size: 1.5em;
        /* Adjust title size for smaller screens */
    }

    .language-buttons .button {
        padding: 8px 16px;
        font-size: 0.9em;
    }
}
</style>
